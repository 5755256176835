import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { Trans, useTranslation } from 'react-i18next';
import RadioGroupField from 'components/RadioGroupField';
import ToolTip from 'components/ToolTip';
import { Job, JobNegotiableSections, JobPost } from 'models/Job';
import Notice from 'components/Notice';
import InfoCircleIcon from 'icons/InfoCircle.icon';
import { FormApi } from 'final-form';
import InputField from 'components/InputField';
import PercentIcon from 'icons/Percent.icon';
import dates from 'utils/dates';
import { Button } from 'ncoded-component-library';
import AddIcon from 'icons/Add.icon';
import DateTimeField from 'components/DateTimeField';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import dayjs from 'dayjs';
import formValidators from 'utils/formValidators';
import DeadlinePreview from './DeadlinePreview';

import './DeadlinePolicy.styles.scss';
import './DeadlinePolicy.styles.responsive.scss';
import { getDeadlinePolicyChanges, isPendingChanges } from 'utils/job-changes';
import JobChanges from 'components/JobChanges';
import WarningIcon from 'icons/Warning.icon';
import ReactPlayer from 'react-player';
import Accordion from 'components/Accordion';

const MIN_PERCENT = 0;
const MAX_PERCENT = 100;
const MAX_DEADLINES_OPTIONS = 6;

const keepPercentageInBoundary = (val: string) => {
  if (!val) return val;

  const valAsNumber = Number.parseFloat(val);

  if (valAsNumber > MAX_PERCENT) return MAX_PERCENT;
  if (valAsNumber < MIN_PERCENT) return MIN_PERCENT;

  return valAsNumber;
};

type DeadlinePolicyProps = {
  className?: string;
  isEdit?: boolean;
  valuesFromForm: Pick<JobPost<'form'>, 'deadlines' | 'hasDeadlinePolicy'>;
  disabled?: boolean;
  hasNoEndDate: boolean;
  formApi: FormApi<JobPost<'form'>>;
  jobEndDate: string;
  isPricingByProject: boolean;
  initialDeadlines?: Pick<JobPost<'form'>, 'deadlines'>['deadlines'];
  getLockButton: (sectionName: JobNegotiableSections) => JSX.Element;
  collapsable?: boolean;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedDeadlinePolicy'>>;

const DeadlinePolicy: React.FC<DeadlinePolicyProps> = (props) => {
  const {
    className,
    isEdit = true,
    disabled,
    hasNoEndDate,
    jobEndDate,
    valuesFromForm,
    formApi,
    isPricingByProject,
    prevVersion,
    initialDeadlines,
    versionState,
    isLockedDeadlinePolicy,
    getLockButton,
    collapsable = false,
  } = props;

  const { t } = useTranslation();

  const { hasDeadlinePolicy: formHasDeadlinePolicy, deadlines } =
    valuesFromForm;

  const classes = classNames('anys-deadline-policy', className);

  const hasDeadlinePolicy = formHasDeadlinePolicy === 'true';

  const hasOnlyOneDeadline = deadlines?.length === 1;

  const messages = useMemo(
    () => ({
      title: t('DeadlinePolicy.title'),
      startDate: t('General.startDate'),
      startTime: t('General.startTime'),
      endDate: t('General.endDate'),
      endTime: t('General.endTime'),
      deadline: t('General.deadline'),
      heWillGet: t('DeadlinePolicy.heWillGet'),
      ofJobValue: t('DeadlinePolicy.ofJobValue'),
      addDeadline: t('DeadlinePolicy.addDeadline'),
      removeDeadline: t('DeadlinePolicy.removeDeadline'),
      dateRequired: t('General.dateTimeRequired'),
      and: t('General.and'),
      pecentRequired: t('General.valueRequired'),
    }),
    [t],
  );

  const deadlinePolicyOptions = useMemo(
    () => [
      { label: t('General.yes'), value: 'true' },
      { label: t('General.no'), value: 'false' },
    ],
    [t],
  );

  const secondToLastDeadline = useMemo(
    () => (deadlines ? deadlines[deadlines.length - 2] : null),
    [deadlines],
  );

  const secondToLastDeadlineDate = secondToLastDeadline?.toDate
    ? dates.formatDeadlineDate(secondToLastDeadline.toDate)
    : ` ${messages.deadline.toLowerCase()} `;

  const lockButton = useMemo(
    () => (isEdit ? getLockButton('isLockedDeadlinePolicy') : null),
    [getLockButton, isEdit],
  );
  const showAddDeadlineOption =
    isEdit && hasDeadlinePolicy && deadlines.length < MAX_DEADLINES_OPTIONS;

  const deadlineChanges =
    prevVersion && isPendingChanges(versionState)
      ? getDeadlinePolicyChanges(
          t,
          jobEndDate ? new Date(jobEndDate) : null,
          {
            hasDeadlinePolicy: `${prevVersion.hasDeadlinePolicy}`,
            deadlines: prevVersion.deadlines?.map((d) => ({
              ...d,
              fromDate: d.fromDate ? new Date(d.fromDate) : null,
              toDate: d.toDate ? new Date(d.toDate) : null,
            })),
          },
          { hasDeadlinePolicy: formHasDeadlinePolicy, deadlines },
          prevVersion.isLockedDeadlinePolicy,
          isLockedDeadlinePolicy,
        )
      : null;

  const handleDeadlinePolicyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const deadlinePolicyValue = event.target
        .value as typeof formHasDeadlinePolicy;

      const hasPolicy = deadlinePolicyValue === 'true';

      const hasInitialDeadlines = initialDeadlines?.length > 0;

      if (hasPolicy) {
        formApi.change(
          'deadlines',
          hasInitialDeadlines
            ? initialDeadlines
            : [
                {
                  fromDate: new Date(jobEndDate),
                  toDate: undefined,
                  percentOfJobValue: null,
                },
              ],
        );
      } else {
        formApi.change('deadlines', []);
      }
    },
    [formApi, initialDeadlines, jobEndDate],
  );

  const addDeadline = useCallback(() => {
    if (deadlines.length === MAX_DEADLINES_OPTIONS) return;
    formApi.change('deadlines', [
      ...deadlines,
      {
        fromDate: deadlines[deadlines.length - 1].toDate,
        toDate: undefined,
        percentOfJobValue: null,
      },
    ]);
  }, [deadlines, formApi]);

  const removeDeadline = useCallback(
    (index: number) => {
      const newDeadlines = [...deadlines];

      const prevDeadline = newDeadlines[index - 1];
      const nextDeadline = newDeadlines[index + 1];

      if (prevDeadline && nextDeadline) {
        prevDeadline.toDate = nextDeadline.fromDate;
      } else if (nextDeadline) {
        nextDeadline.fromDate = new Date(jobEndDate);
      }

      formApi.change(
        'deadlines',
        newDeadlines.filter((_, i) => i !== index),
      );
    },
    [deadlines, formApi, jobEndDate],
  );

  const getLeftDeadlineDate = useCallback(
    (deadlineIndex: number, deadlineFrom: Date) => {
      if (deadlineFrom) return dayjs(deadlineFrom);

      for (let index = deadlineIndex - 1; index >= 0; index--) {
        const { fromDate, toDate } = deadlines[index] || {};

        const date = toDate || fromDate;

        if (date && +date !== +deadlineFrom) return dayjs(date);
      }

      return undefined;
    },
    [deadlines],
  );

  const getRightDeadlineDate = useCallback(
    (deadlineIndex: number, deadlineTo: Date) => {
      for (let index = deadlineIndex + 1; index < deadlines.length; index++) {
        const { fromDate, toDate } = deadlines[index] || {};

        const date = fromDate || toDate;

        if (date && +date !== +deadlineTo) return dayjs(date);
      }

      return undefined;
    },
    [deadlines],
  );

  // If jobEndDate updates, reset deadlines
  useEffectSkipFirst(() => {
    if (isEdit && hasDeadlinePolicy)
      formApi.change('deadlines', [
        {
          fromDate: new Date(jobEndDate),
          toDate: undefined,
          percentOfJobValue: null,
        },
      ]);
  }, [jobEndDate]);

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable || isEdit,
  };

  const AccordionHeader = () => {
    return (
      <div className="anys-deadline-policy__title">
        {messages.title}
        {deadlineChanges ? (
          <ToolTip
            t={t}
            icon={WarningIcon}
            tooltipName="deadline-changes"
            className="anys-deadline-policy__title__changes"
          >
            <JobChanges
              changedFrom={deadlineChanges.changedFrom}
              changedTo={deadlineChanges.changedTo}
            />
          </ToolTip>
        ) : null}
        {isEdit && (
          <div className="anys-deadline-policy__row">
            {lockButton}
            <ToolTip t={t} tooltipName="deadline-changes-tooltip">
              <ReactPlayer width="100%" url="https://youtu.be/XV_6MnmJ3PA" />
              <p style={{ whiteSpace: 'pre-line' }}>
                {t('Preview.deadlinePolicy')}
              </p>
            </ToolTip>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        {hasNoEndDate && (
          <Notice className="anys-deadline-policy__notice">
            <InfoCircleIcon className="anys-deadline-policy__notice__icon" />
            <div>
              <Trans
                i18nKey="DeadlinePolicy.deadlinePolicyPrerequisite"
                components={{
                  semiBold: (
                    <span className="anys-deadline-policy__semi-bold" />
                  ),
                }}
                values={{
                  firstRequirement: isPricingByProject
                    ? messages.endDate
                    : messages.startDate,
                  secondRequirement: isPricingByProject
                    ? messages.endTime
                    : messages.startTime,
                }}
              />
            </div>
          </Notice>
        )}
        {isEdit && (
          <RadioGroupField
            name="hasDeadlinePolicy"
            options={deadlinePolicyOptions}
            direction="row"
            disabled={hasNoEndDate || disabled}
            onChange={handleDeadlinePolicyChange}
            className="anys-deadline-policy__has-deadline-policy-radio"
          />
        )}

        {hasDeadlinePolicy && (
          <div className="anys-deadline-policy__deadlines">
            <Trans
              i18nKey="DeadlinePolicy.beforeFirstDeadline"
              components={{
                underline: <u />,
              }}
              values={{
                jobEnd: jobEndDate
                  ? dates.formatDeadlineDate(jobEndDate)
                  : `"${t('General.endTime').toLowerCase()}"`,
              }}
            />

            {deadlines?.map((d, index) => {
              const { id, fromDate, toDate, percentOfJobValue } = d;

              const isLastDeadline = index === deadlines.length - 1;

              const key = id || d.fromDate ? +d.fromDate + index : index;

              const leftLimit = getLeftDeadlineDate(index, fromDate);

              const rightLimit = getRightDeadlineDate(index, toDate);

              const nextDeadline = deadlines[index + 1];

              const validators = formValidators.composeValidators(
                formValidators.requiredDate(messages.dateRequired),
                formValidators.dateIsAfter(
                  t('DeadlinePolicy.minDeadlineDateValidation', {
                    deadline: dates.formatDeadlineDate(fromDate),
                  }),
                  fromDate,
                ),
                formValidators.dateIsBefore(
                  t('DeadlinePolicy.maxDeadlineDateValidation', {
                    deadline: dates.formatDeadlineDate(rightLimit?.toDate()),
                  }),
                  rightLimit?.toDate() || Number.MAX_SAFE_INTEGER,
                  true,
                ),
              );

              return (
                <div
                  key={key}
                  className="anys-deadline-policy__deadlines__deadline"
                >
                  <Trans
                    i18nKey={
                      isLastDeadline
                        ? 'DeadlinePolicy.afterLastDeadline'
                        : 'DeadlinePolicy.betweenDeadlines'
                    }
                    components={{
                      underline: <u />,
                      semiBold: (
                        <span
                          className={classNames({
                            'anys-deadline-policy__semi-bold':
                              !hasOnlyOneDeadline,
                          })}
                        />
                      ),
                    }}
                    values={
                      isLastDeadline
                        ? {
                            deadline: hasOnlyOneDeadline
                              ? ` "${messages.endTime}" `.toLowerCase()
                              : secondToLastDeadlineDate,
                          }
                        : {}
                    }
                  />
                  {!isLastDeadline ? (
                    <>
                      {fromDate
                        ? dates.formatDeadlineDate(fromDate)
                        : ` "${messages.endTime}" `.toLowerCase()}

                      {` ${messages.and} `.toLowerCase()}

                      {isEdit ? (
                        <DateTimeField
                          name={`deadlines.${index}.toDate`}
                          placeholder="dd/mm/yyyy --:-- --"
                          validate={validators}
                          leftLimit={leftLimit}
                          rightLimit={rightLimit}
                          className="anys-deadline-policy__deadlines__date-time"
                          inputClassName="anys-deadline-policy__deadlines__date-time__input"
                          onChange={(date) => {
                            nextDeadline.fromDate = date as Date;
                          }}
                          isSingleInput
                          disabled={disabled}
                        />
                      ) : toDate ? (
                        dates.formatDeadlineDate(toDate)
                      ) : null}
                    </>
                  ) : null}

                  {messages.heWillGet}
                  {isEdit ? (
                    <InputField
                      name={`deadlines.${index}.percentOfJobValue`}
                      suffixNode={<PercentIcon />}
                      className="anys-deadline-policy__deadlines__percent"
                      inputClassName="anys-deadline-policy__deadlines__percent__input"
                      type="number"
                      isPureNumberInput
                      // Keep the val in [min,max] and parse to number
                      parse={(val) => keepPercentageInBoundary(val) as any}
                      validate={formValidators.required(
                        messages.pecentRequired,
                      )}
                      min={MIN_PERCENT}
                      max={MAX_PERCENT}
                      disabled={disabled}
                    />
                  ) : (
                    `${percentOfJobValue}%`
                  )}
                  {messages.ofJobValue}

                  {isEdit && !isLastDeadline && (
                    <div className="anys-deadline-policy__deadlines__remove-deadline">
                      <button
                        type="button"
                        onClick={() => removeDeadline(index)}
                        disabled={disabled}
                      >
                        {messages.removeDeadline}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}

            {showAddDeadlineOption && (
              <Button
                type="button"
                variant="link"
                onClick={addDeadline}
                className="anys-deadline-policy__deadlines__add-deadline"
                disabled={disabled}
              >
                <AddIcon />
                <span>{messages.addDeadline}</span>
              </Button>
            )}
            <DeadlinePreview
              deadlines={deadlines}
              versionState={versionState}
              isLockedDeadlinePolicy={isLockedDeadlinePolicy}
              jobEndDate={jobEndDate ? new Date(jobEndDate) : null}
            />
          </div>
        )}
      </Accordion>
    </Card>
  );
};

export default DeadlinePolicy;
