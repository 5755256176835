import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { FormApi, FormState, SubmissionErrors } from 'final-form';
import { Button } from 'ncoded-component-library';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ContractInfo from '../ContractInfo';
import { Job, JobNegotiableSections, JobPost } from 'models/Job';
import TimeAndPricing from '../TimeAndPricing';
import TypeOfService from '../TypeOfService';
import TotalOfferNegotiability from '../TotalOfferNegotiability';
import DeadlinePolicy from '../DeadlinePolicy';
import CancelationPolicy from '../CancelationPolicy';
import LockButton from 'components/LockButton';
import JobSign from '../../../../../../../components/JobSign';
import { JOB_NEGOTIABLE_PARTS_ARRAY } from 'constants/job';
import SendTo from '../SendTo';
import AddSkillsField from '../AddSkillsField';
import AddAttachments from '../AddAttachments';
import { FormJobTimeAndPricing } from 'models/Job/TimeAndPricing';
import formValidators from 'utils/formValidators';
import ArbitrationSetUp from '../ArbitrationSetUp';
import Modal, { ModalRef } from 'components/Modal';
import useCallbackRef from 'hooks/useCallbackRef';
import { useJobPost } from '../../../JobPost/JobPost.page';
import { calcJobPrice, getJobEndDate } from 'utils/job';
import { Picture, User, NonImageFile } from 'models/User';
import api from 'api';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Notice from 'components/Notice';
import InfoCircleIcon from 'icons/InfoCircle.icon';
import CurrentUserContext from 'providers/CurrentUser/CurrentUser.context';
import CustomLink from 'components/CustomLink';
import useHeaderContent from 'hooks/useHeaderContent';
import StickyBottomContent from 'components/StickyBottomContent';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import useHeaderBack from 'hooks/useHeaderBack';
import ToolTip from 'components/ToolTip';
import { FormJobDeadline } from 'models/Job/Deadline';
import debounce from 'lodash/debounce';
import ReactRouterPrompt from 'react-router-prompt';
import { INBOX_CHAT_USER_ITEM_LINK_ID } from 'router/subrouters/Inbox/utils';
import { parseJobPostFormValues } from '../../../JobPost/utils';

import './JobPostForm.styles.scss';
import './JobPostForm.styles.responsive.scss';
import UserScheduleModal from 'components/JobPostPreview/components/UserScheduleModal';
import { parseFormTimeAndPricing } from 'utils/job-parses';
import UserScheduleContext from 'components/JobPostPreview/components/UserScheduleModal/UserScheduleProvider/UserSchedule.context';
import confirm from 'modules/confirm';
import useGetSkillGroups from '../../../../../../../hooks/useGetSkillGroups';
import ReactPlayer from 'react-player';

const isPicture = (obj: Picture | File | NonImageFile): obj is Picture =>
  !!(obj as Picture).url;

type JobPostFormProps = {
  className?: string;
  onSubmit: (
    values: JobPost<'form'>,
    formApi: FormApi<JobPost<'form'>>,
    isSubmitFromSign: boolean,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  initialValues: Partial<JobPost<'form'>>;
  inProgress: boolean;
  isOwnJob: boolean;
  provider?: Partial<User>;
  client?: Partial<User>;
  isSignedByClient?: boolean;
  isSignedByProvider?: boolean;
  jobVersion?: number;
  jobCommonId?: number;
  isDraft: boolean;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState'>>;

const JobPostForm: React.FC<JobPostFormProps> = (props) => {
  const {
    className,
    inProgress,
    isOwnJob,
    provider,
    client,
    isSignedByClient,
    isSignedByProvider,
    jobVersion,
    jobCommonId,
    prevVersion,
    isDraft,
    initialValues,
    versionState,
    onSubmit,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasClickedSign, setHasClickedSign] = useState(false);

  const [hasClickedAnyLink, setHasClickedAnyLink] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const prevPathRef = useRef<string | -1>('/inbox');

  const { allSkills, skillOptions, isLoading } = useGetSkillGroups();

  const formApi = useRef<FormApi<JobPost<'form'>>>();
  const [fieldsDiv, fieldsDivRef] = useCallbackRef<HTMLDivElement>();

  const userScheduleModal = useRef<ModalRef>();

  const { currentUser } = useContext(CurrentUserContext);

  const { selectedSchedule } = useContext(UserScheduleContext);

  const isInbox = location.pathname?.startsWith('/inbox');
  const isJobRoute = location.pathname.includes('/job/');
  const isContractRoute = location.pathname.includes('/contract/');

  const isFromMyOffers =
    (location.state as { for?: 'MyOffers' })?.for === 'MyOffers';

  const isContract = isSignedByClient && isSignedByProvider;

  const classes = classNames('anys-job-post-form', className);

  const { createUpdateJobPost, setJobPost } = useJobPost();

  const { createEntityLink } = useInboxLink();

  const [sendSaveOfferModal, sendSaveOfferModalRef] =
    useCallbackRef<ModalRef>(null);

  const messages = useMemo(
    () => ({
      title: t('General.createAdOffer'),
      send: t('General.send'),
      cancel: t('General.cancel'),
      dismiss: t('General.dismiss'),
      pricingMinHourError: t('JobForm.minHoursMustBeLess'),
      priceRequired: t('General.priceRequired'),
      negotiabilityNotice: t('JobForm.negotiabilityNotice'),
      negotiable: t('General.negotiable'),
      nonNegotiable: t('General.nonNegotiable'),
      confirmChanges: t('General.confirmChanges'),
      requestChange: t('General.requestChange'),
      requestChanges: t('General.requestChanges'),
      hourRequired: t('General.hourRequired'),
      dateTimeRequired: t('General.dateTimeRequired'),
      contractInfo: t('General.contractInfo'),
      addToOffers: t('General.addToOffers'),
    }),
    [t],
  );

  const isOwnConfirmChanges = initialValues.id && isOwnJob;

  const submitActionText = isFromMyOffers
    ? messages.addToOffers
    : isContractRoute
      ? messages.requestChanges
      : isOwnConfirmChanges
        ? messages.confirmChanges
        : !isOwnJob
          ? messages.requestChange
          : messages.send;

  const isProvideJob = initialValues.type === 'Provide';

  const routePath = isContractRoute
    ? 'contract'
    : isJobRoute
      ? 'job'
      : 'job-post';

  const jobLinkPath = isInbox
    ? createEntityLink(
        'view',
        routePath,
        isContractRoute || isJobRoute ? jobCommonId : initialValues.id,
      )
    : `/${routePath}/${
        isContractRoute || isJobRoute ? jobCommonId : initialValues.id
      }`;

  const hasBothSigns = isSignedByClient && isSignedByProvider;
  const hasOneSign = !hasBothSigns && (isSignedByClient || isSignedByProvider);

  const formTitle = jobVersion
    ? t(
        hasBothSigns
          ? 'General.changeContract'
          : hasOneSign
            ? 'General.changeOffer'
            : 'General.changePreOffer',
        {
          version: jobVersion,
        },
      )
    : messages.contractInfo;

  const canShowPrompt = !!(
    isFormDirty &&
    hasClickedAnyLink &&
    isOwnJob &&
    isDraft
  );

  const handleBackButtonOnMobile = useCallback(
    (prevPath: string | -1) => {
      prevPathRef.current = prevPath;
      sendSaveOfferModal.open();
    },
    [sendSaveOfferModal],
  );

  useHeaderBack(
    isOwnJob && isDraft && isFormDirty ? handleBackButtonOnMobile : null,
    [!!sendSaveOfferModal, isOwnJob, isDraft, isFormDirty],
  );

  useHeaderContent(
    <div className="anys-job-post-form__header-content mobile-header-content">
      <span> {messages.title} </span>
      <Button
        onClick={() => {
          setHasClickedSign(false);

          setTimeout(() => {
            formApi.current.submit();
            formValidators.scrollToFirstError(fieldsDiv);
          }, 1000);
        }}
        className="mobile-header-content__cta"
        disabled={inProgress}
      >
        {submitActionText}
      </Button>
    </div>,
    [submitActionText, !!fieldsDiv, inProgress],
    0,
  );

  const isCurrentUserClient = currentUser?.id === client?.id;

  const getLockButton = useCallback(
    (sectionName: JobNegotiableSections) => {
      if (!formApi.current) return null;

      return <LockButton name={sectionName} disabled={!isOwnJob} />;
    },
    [isOwnJob],
  );

  const handleFormValuesChange = useMemo(
    () =>
      debounce((formState: FormState<JobPost<'form'>>) => {
        setIsFormDirty(formState.dirty);

        // If any of the locks have been changed
        if (
          JOB_NEGOTIABLE_PARTS_ARRAY.some(
            (part) => formState.dirtyFields[part] !== undefined,
          )
        ) {
          let overallLockValue: boolean;

          // Check the state of all of the locks
          JOB_NEGOTIABLE_PARTS_ARRAY.forEach((part) => {
            const lockValue = formState.values[part];

            if (overallLockValue === undefined && lockValue !== undefined) {
              overallLockValue = lockValue;
            }

            overallLockValue = overallLockValue && lockValue;
          });

          // If all of the locks are locked, then
          // the overall value is 'true', so we should
          // toggle the negotiability.
          formApi.current.change(
            'isNegotiable',
            overallLockValue ? 'false' : 'true',
          );
        }
      }, 300),
    [],
  );

  const removeFile = useCallback(
    (fileToRemove: File | Picture | NonImageFile, index: number) => {
      const { values } = formApi.current.getState();

      const { attachments, attachmentIdsToDelete = [] } = values;
      const arrayOfAttachments = Array.from(attachments);

      formApi.current.batch(() => {
        formApi.current.change(
          'attachments',
          arrayOfAttachments.filter((_, i) => index !== i),
        );

        if (isPicture(fileToRemove)) {
          formApi.current.change('attachmentIdsToDelete', [
            ...attachmentIdsToDelete,
            fileToRemove.id,
          ]);
        }
      });
    },
    [formApi],
  );

  const onSaveAsDraft = useCallback(
    async (formValues: JobPost<'form'>) => {
      const { id } = await createUpdateJobPost(formValues);

      if (formValues.isSigned) {
        const {
          data: { jobPost },
        } = await api.jobPost.signJobPost(id);

        setJobPost((old) => ({ ...old, isSigned: jobPost.isSigned }));
      }

      sendSaveOfferModal.close();

      navigate(isInbox ? createEntityLink('edit', 'job-post', id) : `/inbox`);
    },
    [
      createEntityLink,
      createUpdateJobPost,
      isInbox,
      navigate,
      sendSaveOfferModal,
      setJobPost,
    ],
  );

  const getSignedStates = useCallback(
    (isSigned: boolean) => {
      if (isDraft)
        return {
          hasProviderSigned:
            !isCurrentUserClient && (isSigned || isSignedByProvider),
          hasClientSigned:
            isCurrentUserClient && (isSigned || isSignedByClient),
        };

      if (isContractRoute) {
        return { hasProviderSigned: true, hasClientSigned: true };
      }

      if (!isJobRoute) {
        return {
          hasProviderSigned: isOwnJob
            ? isProvideJob && isSigned
            : isSignedByProvider,
          hasClientSigned: isOwnJob
            ? !isProvideJob && isSigned
            : isSignedByClient,
        };
      }

      return {
        hasProviderSigned: isCurrentUserClient && isSignedByProvider,
        hasClientSigned: !isCurrentUserClient && isSignedByClient,
      };
    },
    [
      isContractRoute,
      isCurrentUserClient,
      isDraft,
      isJobRoute,
      isOwnJob,
      isProvideJob,
      isSignedByClient,
      isSignedByProvider,
    ],
  );

  // const requiredTimeAndPricing = useRef(false);

  const validateTimeAndPricing = useCallback(
    (timeAndPricing: FormJobTimeAndPricing, hasFreeCancelation: boolean) => {
      const {
        minHours,
        maxHours,
        totalHours,
        type,
        startDate,
        price,
        pricingDurationType,
        importAvailability,
      } = timeAndPricing || {};

      const isHourly = type === 'Hourly';

      const isHourlyFlexible = isHourly && pricingDurationType === 'Flexible';

      const timeAndPricingErrors: Partial<
        Record<keyof FormJobTimeAndPricing, string>
      > = {};

      if (minHours > 0 && maxHours > 0 && minHours > maxHours) {
        timeAndPricingErrors.minHours = messages.pricingMinHourError;
      }

      if (!hasClickedSign && !isContract) return timeAndPricingErrors;

      timeAndPricingErrors.price = formValidators.required(
        messages.priceRequired,
      )(price);

      if (!isHourly && hasFreeCancelation) return timeAndPricingErrors;

      if (!importAvailability || !hasFreeCancelation) {
        timeAndPricingErrors.startDate = formValidators.requiredDate(
          messages.dateTimeRequired,
        )(startDate);
      }

      if (isHourlyFlexible) {
        timeAndPricingErrors.minHours = formValidators.required(
          messages.hourRequired,
        )(minHours);
        timeAndPricingErrors.maxHours = formValidators.required(
          messages.hourRequired,
        )(maxHours);
      } else if (pricingDurationType === 'Strict') {
        timeAndPricingErrors.totalHours = formValidators.required(
          messages.hourRequired,
        )(totalHours);
      }

      return timeAndPricingErrors;
    },
    [hasClickedSign, isContract, messages],
  );

  const validateSendTo = useCallback(
    (sendToTimeline: boolean, sendToUsers: User[]) => {
      if (isOwnConfirmChanges || isFromMyOffers) return;

      if (!(isJobRoute || isContractRoute) && isOwnJob) {
        if (!sendToTimeline && !sendToUsers?.length && !hasClickedSign)
          return t('General.selectRequired');
      }
    },
    [
      hasClickedSign,
      isContractRoute,
      isFromMyOffers,
      isJobRoute,
      isOwnConfirmChanges,
      isOwnJob,
      t,
    ],
  );

  const validateDeadlines = useCallback(
    (deadlines: FormJobDeadline[]) => {
      const deadlinesErrors: Partial<Record<keyof FormJobDeadline, string>>[] =
        [];

      if (!deadlines?.length) return;

      for (let index = 0; index < deadlines.length; index++) {
        const { percentOfJobValue } = deadlines[index];
        const { percentOfJobValue: nextPercent } = deadlines[index + 1] || {};

        const percent = Math.max(percentOfJobValue - 1, 0);

        if (percent < nextPercent) {
          deadlinesErrors[index + 1] = {
            percentOfJobValue: percent
              ? t('General.atMostValidation', {
                  max: percent + '%',
                })
              : t('General.lessThanPreviousDeadline'),
          };
        }
      }

      return deadlinesErrors;
    },
    [t],
  );

  const handleSubmit = useCallback(
    (values: JobPost<'form'>, formApi: FormApi<JobPost<'form'>>) => {
      // MUST CALL THIS ON SIGN AND PAY

      onSubmit(values, formApi, hasClickedSign);

      setHasClickedSign(false);
    },
    [hasClickedSign, onSubmit],
  );

  const getSaveDraftModal = useCallback(
    (
      values: JobPost<'form'>,
      options?: {
        isInPrompt: boolean;
        isOpen: boolean;

        onCancel: (value: unknown) => void;
        onConfirm: (value: unknown) => void;
      },
    ) => {
      const { isInPrompt, isOpen, onCancel, onConfirm } = options || {};

      const modalProps = isInPrompt
        ? { open: isOpen, addSearchParams: false }
        : {};

      return (
        <Modal
          type="no-action"
          hideHeader
          isDark
          isDrawer
          modalName="cancel-create"
          ref={sendSaveOfferModalRef}
          className="anys-job-post-form__modal"
          keepOpenOnRefresh={false}
          {...modalProps}
        >
          <div className="anys-job-post-form__modal__upper-buttons">
            <Button
              className="anys-job-post-form__modal__save-as-draft-button"
              type="button"
              disabled={inProgress}
              onClick={(e) => {
                onConfirm?.(e);
                onSaveAsDraft(values);
              }}
            >
              {t('General.saveAsDraft')}
            </Button>
          </div>
          <hr></hr>
          <Button
            type="button"
            className="anys-job-post-form__modal__cancel-button"
            onClick={(e) => {
              sendSaveOfferModal.close();

              onCancel?.(e);
            }}
          >
            {t('General.cancel')}
          </Button>
          <hr />
          <Button
            type="button"
            className="anys-job-post-form__modal__cancel-button"
            onClick={(e) => {
              sendSaveOfferModal.close();

              if ((jobCommonId || values.id) && !isDraft)
                navigate(jobLinkPath, { replace: true });
              else {
                if (typeof prevPathRef.current === 'string') {
                  navigate(prevPathRef.current, { replace: true });
                } else navigate(prevPathRef.current);
              }

              onConfirm?.(e);
            }}
          >
            {t('General.leave')}
          </Button>
        </Modal>
      );
    },
    [
      inProgress,
      isDraft,
      jobCommonId,
      jobLinkPath,
      navigate,
      onSaveAsDraft,
      sendSaveOfferModal,
      sendSaveOfferModalRef,
      t,
    ],
  );

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      const el = event.target as HTMLElement;

      const isCurrElLink =
        el?.tagName?.toLowerCase() === 'a' &&
        el?.id !== INBOX_CHAT_USER_ITEM_LINK_ID;

      let parentEl = el?.parentElement;
      let tryCount = 5;
      let isParentLink =
        parentEl?.tagName?.toLowerCase() === 'a' &&
        parentEl?.id !== INBOX_CHAT_USER_ITEM_LINK_ID;

      // Try to find if parent is link
      while (parentEl && tryCount && !isParentLink) {
        parentEl = parentEl?.parentElement;

        isParentLink =
          parentEl?.tagName?.toLowerCase() === 'a' &&
          parentEl?.id !== INBOX_CHAT_USER_ITEM_LINK_ID;

        tryCount--;
      }

      tryCount = 5;

      const isLink = isParentLink || isCurrElLink;

      setHasClickedAnyLink(isLink);
    };

    document.addEventListener('mousedown', handleMouseDown, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handleMouseDown, {
        capture: true,
      });
    };
  }, []);

  const openUserScheduleModal = useCallback(() => {
    userScheduleModal.current?.open();
  }, []);

  if (!isOwnJob && !isDraft && initialValues.isNegotiable === 'false')
    return <Navigate to="/" replace />;

  const isEdit = !!initialValues?.id;

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        validate={(values) => {
          const errors: Partial<Record<keyof typeof values, any>> = {};

          if (!values) return errors;

          const {
            timeAndPricing,
            deadlines,
            sendToTimeline,
            sendToUsers,
            hasFreeCancelation,
          } = values;

          errors.timeAndPricing = validateTimeAndPricing(
            timeAndPricing,
            hasFreeCancelation === 'true',
          );

          errors.deadlines = validateDeadlines(deadlines);

          errors.sendToTimeline = validateSendTo(sendToTimeline, sendToUsers);

          return errors;
        }}
        render={(formRenderProps) => {
          const { handleSubmit, initialValues, values, form } = formRenderProps;

          formApi.current = form;

          const {
            id,
            title,
            description,
            timeAndPricing,
            typeOfService,
            freeCancelation,
            hasFreeCancelation,
            hasArbitration,
            arbitrationOptions,
            mainSkill,
            skills,
            attachments = [],
            deadlines,
            hasDeadlinePolicy,
            sendToUsers,
            isSigned,
            isNegotiable,
            isLockedDeadlinePolicy,
            isLockedArbitration,
            isLockedFreeCancelation,
            isLockedTimeAndPricing,
            isLockedTypeOfService,
          } = values;

          const submitDisabled = inProgress;

          const contractInfoValues = {
            title,
            description,
          };

          const cancelationPolicyValues = {
            ...freeCancelation,
            hasFreeCancelation,
          };
          const arbitrationValues = {
            arbitrationOptions,
            hasArbitration,
            currency: timeAndPricing.currency,
          };

          const deadlinePolicyValues = {
            deadlines,
            hasDeadlinePolicy,
          };

          const sendToValues = {
            sendToUsers,
          };

          // Cast to string due to deadline policy useEffect
          const jobEndDate = getJobEndDate(timeAndPricing)?.toString();
          const jobPriceAmount = calcJobPrice(timeAndPricing);

          const { hasProviderSigned, hasClientSigned } =
            getSignedStates(isSigned);

          const onSignChange = async (signed: boolean) => {
            // TODO: check do we need isSigned in form
            // If form is valid, change sign state
            // if (!invalid) form.change('isSigned', !signed);

            // set timeAndPricing as required on signing
            // timeAndPricing isnt required if you want to send
            // requiredTimeAndPricing.current = true;

            if (
              !isOwnJob &&
              timeAndPricing?.importAvailability &&
              !selectedSchedule
            ) {
              await confirm({
                title: t('General.selectScheduleModalInfoInForm'),
                cancelContent: null,
              });

              return;
            }

            setHasClickedSign(true);

            const res = await new Promise((resolve) => {
              setTimeout(() => {
                const { errors, hasValidationErrors } = form.getState();

                if (hasValidationErrors) {
                  // Try to submit form on sign
                  form.submit();
                  formValidators.scrollToFirstError(fieldsDiv);
                }

                const errorFields = Object.keys(errors);

                const isOnlySendToRequired =
                  errorFields?.length === 1 &&
                  errorFields[0] === 'sendToTimeline';

                const hasErrors = isOnlySendToRequired
                  ? false
                  : hasValidationErrors;

                // requiredTimeAndPricing.current = false;

                resolve([hasErrors, parseJobPostFormValues(values)]);
              }, 200);
            });

            return res;
          };

          const onChangeSendToUsers = (users: User[]) =>
            form.change('sendToUsers', users);

          return (
            <form className={classes} onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true, dirtyFields: true, dirty: true }}
                onChange={handleFormValuesChange}
              />

              <div className="anys-job-post-form__contract-title">
                {formTitle}
                <ToolTip t={t} tooltipName="ci-tooltip">
                  <ReactPlayer
                    width="100%"
                    url="https://youtu.be/9tt5nqZS2RM"
                  />
                </ToolTip>
              </div>

              <div className="anys-job-post-form__fields" ref={fieldsDivRef}>
                <ContractInfo
                  disabled={!isOwnJob && isNegotiable === 'false'}
                  prevVersion={prevVersion}
                  valuesFromForm={contractInfoValues}
                  versionState={versionState}
                />
                <AddSkillsField
                  formApi={form}
                  mainSkill={mainSkill}
                  selectedSkills={skills}
                  skills={allSkills}
                  isLoadingSkills={isLoading}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  tooltip={t('JobForm.jobSkillsTooltip')}
                  disabled={!isOwnJob && isNegotiable === 'false'}
                />
                <TimeAndPricing
                  t={t}
                  valuesFromForm={timeAndPricing}
                  getLockButton={getLockButton}
                  formApi={form}
                  disabled={!isOwnJob && isLockedTimeAndPricing}
                  isOwnJob={isOwnJob}
                  prevVersion={prevVersion}
                  initialTimeAndPricing={initialValues.timeAndPricing}
                  versionState={versionState}
                  isLockedTimeAndPricing={isLockedTimeAndPricing}
                  openUserScheduleModal={openUserScheduleModal}
                  isContract={isContract}
                  isProvideJob={isProvideJob}
                  collapsable
                  isEdit={isEdit}
                />
                <TypeOfService
                  t={t}
                  valuesFromForm={typeOfService}
                  initialTypeOfService={initialValues.typeOfService}
                  getLockButton={getLockButton}
                  formApi={form}
                  disabled={!isOwnJob && isLockedTypeOfService}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  isLockedTypeOfService={isLockedTypeOfService}
                  withFullAddress
                  collapsable
                  isEdit={isEdit}
                />
                <CancelationPolicy
                  getLockButton={getLockButton}
                  valuesFromForm={cancelationPolicyValues}
                  formApi={form}
                  disabled={!isOwnJob && isLockedFreeCancelation}
                  jobPriceAmount={jobPriceAmount}
                  currency={timeAndPricing.currency}
                  prevVersion={prevVersion}
                  initialFreeCancelation={initialValues.freeCancelation}
                  versionState={versionState}
                  isLockedFreeCancelation={isLockedFreeCancelation}
                  collapsable
                  isEdit={isEdit}
                />
                <DeadlinePolicy
                  formApi={form}
                  valuesFromForm={deadlinePolicyValues}
                  getLockButton={getLockButton}
                  hasNoEndDate={!jobEndDate}
                  isPricingByProject={timeAndPricing.type === 'By project'}
                  jobEndDate={jobEndDate}
                  disabled={!isOwnJob && isLockedDeadlinePolicy}
                  prevVersion={prevVersion}
                  initialDeadlines={initialValues.deadlines}
                  versionState={versionState}
                  isLockedDeadlinePolicy={isLockedDeadlinePolicy}
                  collapsable
                  isEdit={isEdit}
                />
                <ArbitrationSetUp
                  jobPrice={jobPriceAmount}
                  getLockButton={getLockButton}
                  valuesFromForm={arbitrationValues}
                  formApi={form}
                  disabled={!isOwnJob && isLockedArbitration}
                  prevVersion={prevVersion}
                  skillOptions={skillOptions}
                  initialArbitration={initialValues.arbitrationOptions}
                  versionState={versionState}
                  isLockedArbitration={isLockedArbitration}
                  collapsable
                  isEdit={isEdit}
                />
                <TotalOfferNegotiability
                  t={t}
                  formApi={form}
                  disabled={!isOwnJob}
                  prevVersion={prevVersion}
                  isNegotiable={isNegotiable}
                  versionState={versionState}
                  collapsable
                  isEdit={isEdit}
                />
                <AddAttachments
                  t={t}
                  valuesFromForm={attachments}
                  prevVersion={prevVersion}
                  versionState={versionState}
                  onRemoveFile={removeFile}
                  disabled={!isOwnJob && isNegotiable === 'false'}
                  collapsable
                  isEdit={isEdit}
                />
                <JobSign
                  hasProviderSigned={hasProviderSigned}
                  hasClientSigned={hasClientSigned}
                  isProviderSignDisabled={false}
                  isClientSignDisabled={true}
                  jobId={id}
                  handleSign={onSignChange}
                  firstAvatar="client"
                  isCurrentUserClient={isCurrentUserClient}
                  client={client}
                  provider={provider}
                  isForm
                  collapsable
                  isEdit={isEdit}
                  onSign={async (signed) => {
                    form.change('isSigned', true);
                    await onSubmit({ ...values, isSigned: true }, form, true);

                    return Promise.resolve();
                  }}
                />
                {!(isJobRoute || isContractRoute) &&
                  isOwnJob &&
                  !isFromMyOffers && (
                    <SendTo
                      onChangeSendToUsers={onChangeSendToUsers}
                      valuesFromForm={sendToValues}
                      isProvideJob={isProvideJob}
                    />
                  )}
                <Notice className="anys-job-post-form__negotiability-notice">
                  <InfoCircleIcon />
                  <div>
                    {messages.negotiabilityNotice}
                    <span>
                      {`${
                        isNegotiable === 'true'
                          ? messages.negotiable
                          : messages.nonNegotiable
                      }`.toLowerCase()}
                    </span>
                  </div>
                </Notice>
              </div>

              <StickyBottomContent className="anys-job-post-form__bottom-buttons">
                <div className="anys-job-post-form__desktop-submit">
                  <Button
                    type="button"
                    disabled={submitDisabled}
                    onClick={() => {
                      setHasClickedSign(false);

                      setTimeout(() => {
                        form.submit();
                        formValidators.scrollToFirstError(fieldsDiv);
                      }, 1000);
                    }}
                  >
                    {submitActionText}
                  </Button>
                  {isJobRoute || isContractRoute || !isOwnJob ? (
                    <CustomLink to={jobLinkPath}>
                      {isContractRoute ? messages.dismiss : messages.cancel}
                    </CustomLink>
                  ) : (
                    <Button
                      type="button"
                      variant="link"
                      onClick={() => sendSaveOfferModal.open()}
                    >
                      <span>{messages.cancel}</span>
                    </Button>
                  )}
                </div>
              </StickyBottomContent>

              <ReactRouterPrompt when={canShowPrompt}>
                {({ isActive, onCancel, onConfirm }) =>
                  getSaveDraftModal(values, {
                    isInPrompt: true,
                    isOpen: isActive,
                    onCancel,
                    onConfirm,
                  })
                }
              </ReactRouterPrompt>

              {getSaveDraftModal(values)}

              <UserScheduleModal
                userScheduleModalRef={userScheduleModal}
                user={isProvideJob ? provider : client}
                closeModal={() => userScheduleModal.current?.close()}
                timeAndPricing={parseFormTimeAndPricing(timeAndPricing)}
              />
            </form>
          );
        }}
      />
    </>
  );
};

export default JobPostForm;
