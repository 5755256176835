import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import RadioGroupField from 'components/RadioGroupField';
import { JOB_NEGOTIABILITY, JOB_NEGOTIABLE_PARTS_ARRAY } from 'constants/job';
import utils from 'utils';
import ToolTip from 'components/ToolTip';
import UnlockedPadlockIcon from 'icons/UnlockedPadlock.icon';
import LockedPadlockIcon from 'icons/LockedPadlock.icon';
import { FormApi } from 'final-form';
import { BooleanString, Job, JobPost } from 'models/Job';
import { TFunction } from 'i18next';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './TotalOfferNegotiability.styles.scss';
import { isPendingChanges } from 'utils/job-changes';
import ReactPlayer from 'react-player';
import { Trans } from 'react-i18next';
import Accordion from 'components/Accordion';

type TotalOfferNegotiabilityProps = {
  className?: string;
  formApi: FormApi<JobPost<'form'>>;
  disabled?: boolean;
  isNegotiable: BooleanString;
  t: TFunction;
  collapsable?: boolean;
  isEdit?: boolean;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState'>>;

const TotalOfferNegotiability: React.FC<TotalOfferNegotiabilityProps> = (
  props,
) => {
  const {
    t,
    formApi,
    className,
    disabled,
    prevVersion,
    isNegotiable,
    versionState,
    collapsable = false,
    isEdit,
  } = props;

  const classes = classNames('anys-total-offer-negotiability', className);

  const messages = useMemo(
    () => ({
      title: t('JobForm.totalOfferNegotiability'),
      negotiable: t('General.negotiable'),
      nonNegotiable: t('General.nonNegotiable'),
    }),
    [t],
  );

  const negotiabilityOptions = useMemo(
    () =>
      Object.values(JOB_NEGOTIABILITY).map((type) => {
        const isNegotiable = type === 'Negotiable';

        return {
          value: `${isNegotiable}`,
          label: (
            <span className="anys-total-offer-negotiability__label">
              {t(`General.${utils.camelize(type)}`)}
              {isNegotiable ? <UnlockedPadlockIcon /> : <LockedPadlockIcon />}
            </span>
          ),
        };
      }),
    [t],
  );

  const isNegotiabilityChanged =
    prevVersion &&
    `${prevVersion?.isNegotiable}` !== isNegotiable &&
    isPendingChanges(versionState);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formApi.batch(() => {
        JOB_NEGOTIABLE_PARTS_ARRAY.forEach((part) => {
          // If negotiable (unlocked)
          if (e.target.value === 'true') {
            formApi.change(part, false);
          } else {
            formApi.change(part, true);
          }
        });
      });
    },
    [formApi],
  );

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable || isEdit,
  };

  const AccordionHeader = () => {
    return (
      <div className="anys-total-offer-negotiability__title">
        {messages.title}
        {isNegotiabilityChanged ? (
          <ToolTip
            t={t}
            tooltipName="total-negotiability-changes"
            icon={WarningIcon}
            className="anys-total-offer-negotiability__title__changes"
          >
            <JobChanges
              changedFrom={[
                `${messages.title}: ${
                  prevVersion.isNegotiable
                    ? messages.negotiable
                    : messages.nonNegotiable
                }`,
              ]}
              changedTo={[
                `${messages.title}: ${
                  isNegotiable === 'true'
                    ? messages.negotiable
                    : messages.nonNegotiable
                }`,
              ]}
            />
          </ToolTip>
        ) : null}
        <ToolTip t={t} tooltipName="total-negotiability-tooltip">
          <ReactPlayer width="100%" url="https://youtu.be/eB1JJ4nR0uM" />
          <p style={{ whiteSpace: 'pre-line' }}>
            <Trans
              i18nKey="Preview.totalNegotiability"
              components={{ b: <b /> }}
            />
          </p>
        </ToolTip>
      </div>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        <RadioGroupField
          name="isNegotiable"
          options={negotiabilityOptions}
          direction="row"
          onChange={onChange}
          className="anys-total-offer-negotiability__is-negotiable-radio"
          disabled={disabled}
        />
      </Accordion>
    </Card>
  );
};

export default TotalOfferNegotiability;
